import { toast } from 'react-toastify'
import { defaultToastOptions } from '@/utils/commonToast'
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import { MAX_FILE_SIZE_MB, MAX_FILE_SIZE_BYTES } from '@/utils/fileMaxSizes'
import { putRequest } from './axiosRequests'
import { ALLOWED_IMAGE_TYPES } from '@/utils/constants'

export const redirectToUrl = (page_url) => {
  window.open(page_url, '_blank')
}

export const isValidURL = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

export const handleLogoValidation = (selectedFile) => {
  if (!selectedFile) {
    toast(
      <ErrorMessage message={'No File Selected!'} />,
      defaultToastOptions('red-200')
    )
    return false
  }
  else if (!ALLOWED_IMAGE_TYPES.includes(selectedFile.type)) {
    toast(
      <ErrorMessage message={'Invalid Image Type!'} />,
      defaultToastOptions('red-200')
    )
    return false
  }
  else if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
    toast(
      <ErrorMessage message={`File size is too large. Maximum file size allowed is ${MAX_FILE_SIZE_MB}MB.`} />,
      defaultToastOptions('red-200')
    )
    return false
  }
  else {
    return true
  }
}

export const handleLogoRequest = async (selectedFile, solution, token, setLogoPreviewUrl) => {
  const formData = new FormData()
  formData.append('solution[logo]', selectedFile)

  try {
    const response = await putRequest(`/solutions/${solution.id}`, formData, token)

    if (response.status === 200) {
      toast(
        <SuccessMessage />,
        defaultToastOptions('teal-tint-2')
      )
      setLogoPreviewUrl(URL.createObjectURL(selectedFile))
    }
  } catch (error) {
    toast(
      <ErrorMessage />,
      defaultToastOptions('red-200')
    )
  }
}

export const isObjectEmpty = (obj) => {
  return !Object.values(obj).some(value => value)
}

export const getErrorMessage = (fieldName: string, rule: string, value?: number) => {
  switch (rule) {
    case 'required':
      return `${fieldName} is required`
    case 'minLength':
      return `${fieldName} must be at least ${value} characters`
    case 'maxLength':
      return `${fieldName} must be at most ${value} characters`
    case 'pattern':
      return `Please enter a valid work email address`
    default:
      return `${fieldName} is invalid`
  }
};

export const getQueryParamValue = (paramName) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramName);
};

export const getCategoryUrl = (primaryCategory, solution) => {
  if (primaryCategory?.slug) {
    return `/categories/${primaryCategory.slug}`
  }
  if (solution.categories_slugs && solution.categories_slugs.length === 1) {
    return `/categories/${solution.categories_slugs[0]}`
  }
  return '/marketplace'
}
