export const navLinks = [
  {
    name: 'Events',
    url: '/events',
    external: true,
  },
  {
    name: 'News',
    url: '/topics',
    external: true,
  },
  {
    name: 'About',
    url: '/aboutus',
    external: true,
  },
  {
    name: 'Jobs',
    url: 'https://talentjobs.niceboard.co/',
    external: true,
  },
  {
    name: 'Podcasts',
    url: '/podcasts',
    external: true,
  }
]

export const rightNavLinks = [
  {
    name: 'Login',
    url: '/login',
    external: false,
  },
  {
    name: 'My Profile',
    url: '/users/edit',
    external: false,
  },
]

export const footerNavLinks = [
  {
    name: 'Marketplace',
    url: '/marketplace',
    external: false,
  },
  {
    name: 'Events',
    url: '/events',
    external: true,
  },
  {
    name: 'News',
    url: '/topics',
    external: true,
  },
  {
    name: 'Podcasts',
    url: '/podcasts',
    external: true,
  },
  {
    name: 'About',
    url: '/aboutus',
    external: true,
  },
  {
    name: 'Login',
    url: '/login',
    external: false,
  },
  {
    name: 'Job Board',
    url: 'https://talentjobs.niceboard.co/',
    external: true,
  },
  {
    name: 'Sign Up',
    url: '/',
    external: false,
  },
  {
    name: 'Privacy Policy',
    url: 'https://www.recruitmentmarketing.com/privacy-policy/',
    external: true,
  },
]

export const SocialLinks = {
  linkedin: {
    url: 'https://www.linkedin.com/company/recruitmentmarketingcom/',
    external: true
  },
  facebook: {
    url: 'https://www.facebook.com/RecruitmentMarketingcom',
    external: true
  },
  twitter: {
    url: 'https://twitter.com/RMCmatters',
    external: true
  }
}

export const footerSubNavLinks = [
  {
    name: 'Candidate Experience',
    url: '/topics/candidate-experience/',
    external: true,
  },
  {
    name: 'Employer Brand',
    url: '/topics/employer-brand/',
    external: true,
  },
  {
    name: 'Industry Insights',
    url: '/topics/industry-insights/',
    external: true,
  },
  {
    name: 'TA Media',
    url: '/topics/ta-media/',
    external: true,
  },
  {
    name: 'TA Tech',
    url: '/topics/ta-tech/',
    external: true,
  },
]

export const authLinks = {
  login: '/login',
  signup: '/users/sign_up',
}
