import { useForm, SubmitHandler, Controller } from "react-hook-form"
import Select from "react-select"
import { ErrorMessage } from "@hookform/error-message"
import Modal from "@/components/atoms/modals/Modal"
import Button from "@/components/atoms/buttons/Button"
import { getErrorMessage } from "@/utils/helperFunctions"

import { 
  leadConfirmationModalDescription,
  leadConfirmationModalTitle,
  leadGenerationFormCtaText,
  leadGenerationFormDescription,
  leadGenerationFormTitle,
  leadPasswordModalDescription,
  leadPasswordModalTitle 
} from "@/utils/constants"

import ModalChecks from "./ModalChecks"
import { createJobsNewsletterSubscription, createWeeklyNewsletterSubscription, postRequest, checkForEmailExistence } from "@/utils/axiosRequests"
import { useToken } from "@/components/atoms/forms/Form"
import { memo, useEffect, useState } from "react"
import ConfirmPasswordModal from "@/components/organisms/modals/ConfirmPasswordModal"
import { useTracking } from "react-tracking"

import {
  CurrentTimelineOptions,
  EmailRegex,
  SoftwareAcquisitionRoles,
} from "@/utils/constants"

import ConfirmationModal from "./ConfirmationModal"
import { toast } from "react-toastify"
import ReCaptcha from "@/components/atoms/widgets/Recaptcha"
import { defaultToastOptions } from "@/utils/commonToast"

type LeadGenerationModalProps = {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  solutionId?: number
  formType?: string
  sourceBtn?: string
  solutionName?: string
  currentUser?: schema.User
}

const LeadGenerationModal = memo(
  ({
    openModal,
    setOpenModal,
    solutionId,
    formType,
    sourceBtn,
    solutionName,
    currentUser
  }: LeadGenerationModalProps) => {

    const [openPasswordModal, setOpenPasswordModal] = useState(false)
    const [customerData, setCustomerData] = useState(null)
    const [isSubToWeeklyNewsletter, setIsSubToWeeklyNewsletter] = useState(true)
    const [isSubToJobsNewsletter, setIsSubToJobsNewsletter] = useState(true)
    const [isAgreeToPrivacyPolicy, setIsAgreeToPrivacyPolicy] = useState(true)
    const [isFormValid, setIsFormValid] = useState(false)
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [recaptchaToken, setRecaptchaToken] = useState('')

    const tracking = useTracking()
    const token = useToken()

    const {
      register,
      reset,
      setError,
      handleSubmit,
      control,
      formState: { errors, isValid },
    } = useForm<schema.CustomerLead>({
      defaultValues: {
        first_name: "",
        last_name: "",
        company: "",
        job_title: "",
        email: "",
        current_timeline: "",
        software_acquisition_role: "",
        additional_info: "",
      },
    })

    useEffect(() => {
      let formValid = isAgreeToPrivacyPolicy && isValid
      if (formType === 'leadSubmission')
      {
        formValid = formValid && recaptchaToken 
      }
      setIsFormValid(formValid)
    }, [isValid, isAgreeToPrivacyPolicy, recaptchaToken])

    useEffect(() => {
      if(openConfirmationModal || !(openModal || openPasswordModal)){
        resetCheckboxes()
      }
    },[openConfirmationModal, openPasswordModal])

    const EmailAlreadyExists = async (email) => {
      const res = await checkForEmailExistence(email, token)
      return res.data.status
    }

    const onSubmit: SubmitHandler<schema.CustomerLead> = async (data) => {
      const emailExists = await EmailAlreadyExists(data.email)
      if(!currentUser && emailExists){
          setError("email", {
            type: "manual",
            message: "This email address is already in use. Please try another one or login.",
          });
        return;
      }
      try {
        const newData = {
          ...data,
          terms_accepted: isAgreeToPrivacyPolicy,
        }
        setCustomerData(newData)
        if (currentUser && formType == "leadSubmission") {
          createLead(currentUser.id, data)
        } else {
          createCustomerAndLead()
        }
      } catch (error) {
          toast(<span>Something went wrong! Please try again.</span>)
          setOpenModal(false)
      }
    }

    const subscribeToNewsLetter = async () => {
      setIsLoading(true)
      let response = null
      if(isSubToWeeklyNewsletter)
      { 
        const res = await createWeeklyNewsletterSubscription(currentUser.id, token)
        if(res.status !== 200)
          return res
        response = res
      }
      if (isSubToJobsNewsletter){
        const res = await createJobsNewsletterSubscription(currentUser.id, token)
        if(res.status !== 200)
          return res 
        response = res 
      }  
      setIsLoading(false) 
      setOpenModal(false)
      setOpenConfirmationModal(true)
    }

    const createLead = async (user_id, customerLeadData = customerData) => {
      const formData = new FormData()
      const user = currentUser || customerLeadData

      formData.append(`outside_lead[solution_id]`, `${solutionId}`)
      formData.append(`outside_lead[first_name]`, `${user.first_name}`)
      formData.append(`outside_lead[last_name]`, `${user.last_name}`)
      formData.append(`outside_lead[company]`, `${user.company}`)
      formData.append(`outside_lead[email]`, `${user.email}`)
      formData.append(`outside_lead[role]`, `${user.job_title}`)
      formData.append(`current_timeline`, `${user.current_timeline || customerLeadData.current_timeline}`)
      formData.append(`software_acquisition_role`, `${user.software_acquisition_role || customerLeadData.software_acquisition_role}`)
      formData.append(`additional_info`, `${user.additional_info || customerLeadData.additional_info}`)
      formData.append(`outside_lead[source_button]`, `${sourceBtn}`)
      formData.append(`g_recaptcha_response`, recaptchaToken)

      try {
        const response = await postRequest("/outside_leads", formData, token)
        if (response.status == 200) {
          tracking.trackEvent({
            event_name: "Lead Submission",
            page_url: window.location.pathname,
            event_loggable_type: "RmcEvents::LeadSubmissionLog",
            additional_attributes: {
              user_id: user_id,
              solution_id: solutionId,
              acquisition_role: customerLeadData.software_acquisition_role,
              additional_information: customerLeadData.additional_info,
              implementation_timeline: customerLeadData.current_timeline,
              lead_type: "single_submission",
            },
          })
          setOpenModal(false)
          setOpenConfirmationModal(true)
        }
        return response
      } catch (error) {
        toast(<span>Something went wrong! Please try again.</span>)
        setOpenModal(false)
      }
    }

    const createCustomerAndLead = () => {
      setOpenModal(false)
      setOpenPasswordModal(true)
    }

    const resetCheckboxes = () => {
      setIsAgreeToPrivacyPolicy(true)
      setIsSubToJobsNewsletter(true)
      setIsSubToWeeklyNewsletter(true)
      setRecaptchaToken('')
    }

    return (
      <>
        { openModal &&
          <Modal
          isOpen={openModal}
          onClose={() => {
            setOpenModal(false)
            reset()
            if(!openPasswordModal)
            {
              resetCheckboxes()
            }
          }}
          showCloseButton={true}
          className="mx-4 w-full rounded-xl bg-white p-10 md:mt-0 md:w-[600px] max-h-[600px] overflow-auto sm:max-h-[85%]"
          closeStyle="top-[1.5rem]"
        >
          <h1 className="mb-1 mt-3 flex justify-center sm:text-2xl text-lg font-bold">
            {leadGenerationFormTitle(formType, solutionName)}
          </h1>
          <p className="text-start md:text-sm text-xs mb-[2rem]">
            {leadGenerationFormDescription(formType, currentUser, solutionName)}
          </p>
          {!(currentUser && formType === "newsletter") && (
            <form onSubmit={handleSubmit( isFormValid ? onSubmit : null )}>
              <div className="flex flex-col gap-3">
                {(!currentUser || formType === "newsletter") && (
                  <div className="flex justify-between flex-col gap-3">
                    <div className="form-group flex gap-3 sm:flex-row flex-col">
                      <div className="w-full ">
                        <label htmlFor="text" className="block mb-1">
                          First Name*
                        </label>
                        <input
                          type="text"
                          id="first_name"
                          {...register("first_name", {
                            required: getErrorMessage("First Name", "required"),
                            minLength: {
                              value: 2,
                              message: getErrorMessage(
                                "First Name",
                                "minLength",
                                2
                              ),
                            },
                          })}
                          placeholder="First Name"
                          onBlur={(e) => {
                            e.target.value = e.target.value.trim()
                          }}
                          className="border w-full border-gray-300 rounded-md p-2"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="first_name"
                          render={({ message }) => (
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {" "}
                              {message}{" "}
                            </span>
                          )}
                        />
                      </div>
                      <div className="w-full">
                        <label htmlFor="text" className="block mb-1">
                          Last Name*
                        </label>
                        <input
                          type="text"
                          id="last_name"
                          {...register("last_name", {
                            required: getErrorMessage("Last Name", "required"),
                            minLength: {
                              value: 2,
                              message: getErrorMessage(
                                "Last Name",
                                "minLength",
                                2
                              ),
                            },
                          })}
                          value={currentUser?.last_name}
                          placeholder="Last Name"
                          onBlur={(e) => {
                            e.target.value = e.target.value.trim()
                          }}
                          className="border w-full border-gray-300 rounded-md p-2"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="last_name"
                          render={({ message }) => (
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {" "}
                              {message}{" "}
                            </span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="form-group flex gap-2 sm:flex-row flex-col">
                      <div className="w-full">
                        <label htmlFor="text" className="block mb-1">
                          Company*
                        </label>
                        <input
                          type="text"
                          id="company"
                          value={currentUser?.company}
                          {...register("company", {
                            required: getErrorMessage(
                              "Company Name",
                              "required"
                            ),
                            minLength: {
                              value: 2,
                              message: getErrorMessage(
                                "Company Name",
                                "minLength",
                                2
                              ),
                            },
                          })}
                          placeholder="Company"
                          onBlur={(e) => {
                            e.target.value = e.target.value.trim()
                          }}
                          className="border w-full border-gray-300 rounded-md p-2"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="company"
                          render={({ message }) => (
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {" "}
                              {message}{" "}
                            </span>
                          )}
                        />
                      </div>
                      <div className="w-full">
                        <label htmlFor="text" className="block mb-1">
                          Job Title*
                        </label>
                        <input
                          type="text"
                          id="job_title"
                          value={currentUser?.job_title}
                          {...register("job_title", {
                            required: getErrorMessage("Job title", "required"),
                            minLength: {
                              value: 2,
                              message: getErrorMessage(
                                "Job title",
                                "minLength",
                                2
                              ),
                            },
                          })}
                          placeholder="Job Title"
                          onBlur={(e) => {
                            e.target.value = e.target.value.trim()
                          }}
                          className="border w-full border-gray-300 rounded-md p-2"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="job_title"
                          render={({ message }) => (
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {" "}
                              {message}{" "}
                            </span>
                          )}
                        />
                      </div>
                    </div>
                    <div className="form-group flex flex-col gap-2">
                      <label htmlFor="text" className="block mb-1">
                        Work Email*
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={currentUser?.email}
                        {...register("email", {
                          required: getErrorMessage("Email", "required"),
                          pattern: {
                            value: EmailRegex,
                            message: getErrorMessage("Email", "pattern"),
                          },
                        })}
                        placeholder="Work Email"
                        onBlur={(e) => {
                          e.target.value = e.target.value.trim()
                        }}
                        className="border border-gray-300 rounded-md p-2"
                      />
                      <ErrorMessage
                        errors={errors}
                        name="email"
                        render={({ message }) => (
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginBottom: "5px",
                            }}
                          >
                            {" "}
                            {message}{" "}
                          </span>
                        )}
                      />
                    </div>
                  </div>
                )}

                {(currentUser || formType === "leadSubmission") &&
                  formType !== "newsletter" && (
                    <div className="flex flex-col gap-3">
                      <div className="form-group flex flex-col gap-2">
                        <label
                          htmlFor="current_timeline"
                          className="block mb-1"
                        >
                          Please indicate your current timeline for implementing new solutions/software*
                        </label>
                        <Controller
                          name="current_timeline"
                          control={control}
                          render={({
                            field: { onChange, value },
                            fieldState,
                          }) => (
                            <>
                              <Select
                                options={CurrentTimelineOptions}
                                value={CurrentTimelineOptions.find(
                                  (c) => c.value === value
                                )}
                                onChange={(val) => onChange(val.value)}
                                isSearchable={false}
                                placeholder="Please select"
                              />
                            </>
                          )}
                          rules={{ required: "Current Timeline is required" }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="current_timeline"
                          render={({ message }) => (
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {" "}
                              {message}{" "}
                            </span>
                          )}
                        />
                      </div>

                      <div className="form-group flex flex-col gap-2">
                        <label
                          htmlFor="software_acquisition_role"
                          className="block mb-1"
                        >
                          Please select your role in solution/software acquisition*
                        </label>
                        <Controller
                          name="software_acquisition_role"
                          control={control}
                          render={({
                            field: { onChange, value },
                            fieldState,
                          }) => (
                            <>
                              <Select
                                options={SoftwareAcquisitionRoles}
                                value={SoftwareAcquisitionRoles.find(
                                  (c) => c.value === value
                                )}
                                onChange={(val) => onChange(val.value)}
                                isSearchable={false}
                                placeholder="Please select"
                              />
                            </>
                          )}
                          rules={{
                            required: "Software Acquisition Role is required",
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="software_acquisition_role"
                          render={({ message }) => (
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {" "}
                              {message}{" "}
                            </span>
                          )}
                        />
                      </div>

                      <div className="form-group flex flex-col gap-2 mb-4">
                        <label htmlFor="additional_info">
                          Additional Information{" "}
                          <span className="text-xs text-gray-500">
                            {" "}
                            (Optional){" "}
                          </span>
                        </label>
                        <textarea
                          id="additionalInfo"
                          {...register("additional_info", {
                            maxLength: {
                              value: 2000,
                              message:
                                "Additional Information cannot exceed 2000 characters",
                            },
                          })}
                          placeholder="Enter additional information"
                          className="border border-gray-300 rounded-md p-2 w-full"
                          // maxLength={2000}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="additional_info"
                          render={({ message }) => (
                            <span
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginBottom: "5px",
                              }}
                            >
                              {" "}
                              {message}{" "}
                            </span>
                          )}
                        />
                      </div>
                    </div>
                  )}
                { formType === 'newsletter' && !currentUser && <p className='text-sm text-gray-400'>By signing up, your free profile will be created.</p>}
                <ModalChecks
                  isAgreeToPrivacyPolicy={isAgreeToPrivacyPolicy}
                  setIsAgreeToPrivacyPolicy={setIsAgreeToPrivacyPolicy}
                  isSubToWeeklyNewsletter={isSubToWeeklyNewsletter}
                  setIsSubToWeeklyNewsletter={setIsSubToWeeklyNewsletter}
                  isSubToJobsNewsletter={isSubToJobsNewsletter}
                  setIsSubToJobsNewsletter={setIsSubToJobsNewsletter}
                  showWeeklyNewsletterCheckbox={
                    currentUser || formType === "newsletter" ? false : true
                  }
                  showJobsNewsletterCheckbox={(currentUser && formType !== 'newsletter') ? false : true}
                  showTermsOfServicesCheckbox={currentUser ? false : true}
                />

                {!isAgreeToPrivacyPolicy && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      marginBottom: "5px",
                    }}
                  >
                    {" "}
                    {"You must agree to our terms of service"}{" "}
                  </p>
                )}

                {formType === 'leadSubmission' && (
                  <div className="w-full">
                    <ReCaptcha setRecaptchaToken={setRecaptchaToken} />
                  </div>
                )}

                <div className="flex justify-end mt-6 gap-2">
                  <input
                    type="submit"
                    value={leadGenerationFormCtaText(formType, currentUser)}
                    className={`!inline-block !rounded-xl !transition !duration-300 !focus:outline-none ${isFormValid ? "!bg-dark-blue !text-white" : "!bg-dark-blue !text-white !opacity-50"} !font-bold !px-8 !py-[14px] !text-md !min-w-[164px]  !cursor-pointer`}
                  ></input>
                </div>
              </div>
            </form>
          )}
          {currentUser && formType === "newsletter" && (
            <>
              { !currentUser?.job_newsletter_subscribed &&
                <ModalChecks
                  isAgreeToPrivacyPolicy={isAgreeToPrivacyPolicy}
                  setIsAgreeToPrivacyPolicy={setIsAgreeToPrivacyPolicy}
                  isSubToWeeklyNewsletter={isSubToWeeklyNewsletter}
                  setIsSubToWeeklyNewsletter={setIsSubToWeeklyNewsletter}
                  isSubToJobsNewsletter={isSubToJobsNewsletter}
                  setIsSubToJobsNewsletter={setIsSubToJobsNewsletter}
                  showWeeklyNewsletterCheckbox={false}
                  showJobsNewsletterCheckbox={true}
                  showTermsOfServicesCheckbox={false}
                />
            }
            <div className="flex justify-end">
              <Button
                variant="teal"
                className="!px-4 !py-2 !self-start"
                onClick={() => subscribeToNewsLetter()}
                disabled={isLoading}
              >
                Sign Up
              </Button>
            </div>
            </>
          )}
        </Modal>}

        {openPasswordModal && (
          <ConfirmPasswordModal
            openModal={openPasswordModal}
            setOpenModal={setOpenPasswordModal}
            data={customerData}
            createLead={createLead}
            formTitle={leadPasswordModalTitle(formType)}
            formDescription={leadPasswordModalDescription(formType, solutionName)}
            setOpenConfirmationModal={setOpenConfirmationModal}
            formType={formType}
            isSubToWeeklyNewsletter={isSubToWeeklyNewsletter}
            isSubToJobsNewsletter={isSubToJobsNewsletter}
          />
        )}
        {openConfirmationModal && (
          <ConfirmationModal
            title={leadConfirmationModalTitle(formType, currentUser)}
            description={leadConfirmationModalDescription(formType, currentUser, solutionName)}
            openConfirmationModal={openConfirmationModal}
            setOpenConfirmationModal={setOpenConfirmationModal}
            formType={formType}
          />
        )}
      </>
    )
  }
)

export default LeadGenerationModal
